import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Seamless from "../components/SeamlessIframe"

const AcademicAdvantage = () => {
  return (
    <Layout language="en">
      <SEO
        title="Academic Advantage at Muir Oral, Facial, & Dental Implant Surgery"
        description="Learn more about the 2023 Academic Advantage program at Academic Advantage at Muir Oral, Facial, & Dental Implant Surgery"
        lang="en"
      />
      <div className="edu-23">
        <div className="edu-23__section">
          <div className="edu-23__container">
            <img
              src="https://res.cloudinary.com/nuvolum/image/upload/f_auto,q_99/v1/Programs/2023-edu-desktop-hero-logo"
              alt="academic advantage logo"
              className="edu-23__hero--desktop"
            />
            <img
              src="https://res.cloudinary.com/nuvolum/image/upload/f_auto,q_99/v1/Programs/2023-edu-mobile-hero-logo"
              alt="academic advantage logo"
              className="edu-23__hero--mobile"
            />
          </div>
        </div>

        <div className="edu-23__section">
          <div className="edu-23__container">
            <div className="edu-23__even-grid">
              <div>
                <h1>Let's Talk About Your Future</h1>
                <p>Let's Talk About Your Future</p>
                <ul>
                  <li>
                    When and how to start planning for your healthcare career
                  </li>
                  <li>Tips on preparing for college and medical school</li>
                  <li>
                    The daily routine of a medical and dental professional
                  </li>
                  <li>What you can expect from a medical career</li>
                  <li>What it’s like to be an entrepreneur and a doctor</li>
                  <li>The many healthcare career paths you can take</li>
                </ul>
                <p>
                  In addition to learning from talented and respected medical
                  professionals, representatives from local colleges and
                  nonprofits will be available to provide more information about
                  college admissions, specific topics, and answer questions.
                </p>
              </div>
              <div className="edu-23__date-time">
                <h2>DATE, TIME, & LOCATION:</h2>

                <p>
                  <span className="edu-23__blue bold">SATURDAY</span>
                  <br />
                  May 6, 2023
                  <br />
                  9:00 AM–12:00 PM
                </p>
                <p>
                  <span className="edu-23__blue bold">Cal State East Bay</span>
                  <br />
                  4700 Ygnacio Valley Rd
                  <br />
                  Concord, CA 94521
                </p>

                <p>
                  <span className="edu-23__blue bold">Oak Room</span>
                  <br />
                  Speaking Event
                </p>

                <p>
                  <span className="edu-23__blue bold">Redwood Room</span>
                  <br />
                  Vendor Booths
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="edu-23__section">
          <div className="edu-23__container">
            <div className="edu-23__speakers">
              <h2>FEATURED SPEAKERS:</h2>
              <div className="edu-23__even-grid">
                <div>
                  {/* <p>
                    <span className="bold">Doctor Name DDS</span>
                    <br />
                    Oral &amp; Maxillofacial Surgeon at
                    <br />
                    Oral Surgery Specialists of Oklahoma
                  </p> */}
                  <ul>
                    <li>Oral and Maxillofacial Surgeon</li>
                    <li>Neurosurgeon</li>
                    <li>College Counselor</li>
                    <li>General Dentist</li>
                    <li>Endodontist</li>
                    <li>Prosthodontist</li>
                  </ul>
                  <p className="bold">And More TBA!</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="edu-23__apply">
          <h3>
            Apply for our scholarship and request to attend the event by filling
            out the form below.
          </h3>
        </div>

        <div className="edu-23__form">
          <Seamless src="https://secureform.seamlessdocs.com/f/b52399fce2ae62e5fd11cd772967769a?embedded=true" />
        </div>
      </div>
    </Layout>
  )
}

export default AcademicAdvantage
